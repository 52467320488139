exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-course-index-jsx": () => import("./../../../src/components/Course/index.jsx" /* webpackChunkName: "component---src-components-course-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-thanks-team-jsx": () => import("./../../../src/pages/thanks-team.jsx" /* webpackChunkName: "component---src-pages-thanks-team-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

